import { FaCheckCircle } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { ScrollRestoration } from "react-router-dom";
import clinic from "../../assets/clinic.mp4";
import doctorsInterface from "../../assets/doctorsInterface.jpeg";
import clinicServiceLogo from "../../assets/png/clinic_service_logo.png";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import ContactCTA from "../contact_us/widgets/contact_cta/contact_cta";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./clinic_service_screen.css";
export default function ClinicServiceScreen() {
  const clinicData = [
    {
      title: "Patients Record",
      description:
        "Store, access, and share patient records effortlessly with robust security and seamless integration across your clinic.",
      points: [
        "Master Record of all Patients",
        "Medical History",
        "Prescription History",
        "Lab test history",
        "Book Appointments",
      ],
      url: "https://images.pexels.com/photos/7578808/pexels-photo-7578808.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Invoicing",
      description:
        " Our automated billing streamlines the revenue cycle, freeing your valuable time to focus on what truly matters - delivering exceptional care to your patients.",
      points: [
        "Patient Invoice",
        "Credit Receipts",
        "Sales Refunds",
        "Patient Credit note",
      ],
      url: "https://images.pexels.com/photos/7108318/pexels-photo-7108318.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Doctors Interface",
      description:
        "Doctors click, care, repeat. Intuitive interface simplifies workflows, empowers optimal patient care.",
      points: [
        "Doctors Profile",
        "Doctors Appointment schedule",
        "Patient Details",
        "Treatment History",
        "Physical Examination",
        "Diagnosis",
        "Prescription",
        "Referrals",
        "Last Visit Record",
      ],
      url: doctorsInterface,
    },
    {
      title: "Insurance Management",
      description:
        "Effortless insurance handling: Eliminate complexities, streamline claims, and maximize reimbursements.",
      points: [
        "Insurance Companies",
        "Different Policies setup",
        "Integration with Patients Billing",
        "Receivables from Insurance Companies",
      ],
      url: "https://images.pexels.com/photos/7163947/pexels-photo-7163947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Enquiries / Reports",
      description:
        "Answers at your fingertips, insights for success. Streamline patient enquiries and generate insightful reports for optimized care and efficient operations.",
      points: [
        "Periodic Sales",
        "Periodic Insurance Sales",
        "Periodic Doctors Cash Report",
        "Patient Receivables",
        "Other Customized Reports",
      ],
      url: "https://images.pexels.com/photos/7580294/pexels-photo-7580294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
  ];
  return (
    <div>
      <ScrollRestoration />
      <Navbar />
      <div className="clinic-service-screen-parent">
        <div className="clinic-service-header">
          <video
            src={clinic}
            className="clinic-service-header-video"
            autoPlay={true}
            loop={true}
            preload="auto"
          />
          <div className="clinic-service-title-container">
            <div className="clinic-service-title-content-container">
              <Tilt
                className="clinic-service-header-image-container"
                perspective={150}
                tiltReverse={true}
              >
                <img
                  className="clinic-service-header-image"
                  src={clinicServiceLogo}
                  alt="service-logo"
                />
              </Tilt>
              <div className="clinic-service-header-text">
                Clinic
                <span className="clinic-service-header-subtitle">
                  MANAGEMENT SYSTEM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "linear-gradient(180deg, #EBF7FE 2rem, #00000000)",
            width: "100%",
          }}
        >
          <div className="clinic-gap"></div>
        </div>
        {clinicData.map((e, index) => (
          <div key={e}>
            <ClinicServiceInfo key={e} data={e} isReverse={index % 2 !== 0} />
            <VerticalSpacer height={"40px"} />
          </div>
        ))}
      </div>
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}

function ClinicServiceInfo({ data, isReverse }) {
  return (
    <div className="clinic-info-root">
      <div
        className="clinic-info-parent"
        style={{
          "--clinic-service-reverse":
            isReverse === true ? "row-reverse" : "row",
        }}
      >
        <div
          className="clinic-info-content"
          style={{
            "--clinic-content-padding":
              isReverse === true ? "0 0 0 16px " : "0 16px 0 0",
          }}
        >
          <div
            className="clinic-info-title"
            style={{
              "--clinic-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.title}
          </div>
          <div
            className="clinic-info-description"
            style={{
              "--clinic-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.description}
          </div>
          <div className="clinic-info-pointers-main">
            {data.points.map((e) => (
              <h3 key={e} className="clinic-info-point">
                <FaCheckCircle className="clinic-info-point-icon" />
                {e}
              </h3>
            ))}
          </div>
        </div>
        <div className="clinic-info-image">
          <img
            src={data.url}
            alt="infoImage"
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="clinic-info-pointers">
        {data.points.map((e) => (
          <h3 key={e} className="clinic-info-point">
            <FaCheckCircle className="clinic-info-point-icon" />
            {e}
          </h3>
        ))}
      </div>
    </div>
  );
}
