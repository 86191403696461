import { FaCircleArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import countour from "../../../../assets/png/countour.png";
import "./contact_cta.css";

export default function ContactCTA() {
  return (
    <div className="contact-cta-root">
      <div
        style={{
          backgroundImage: `url(${countour})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          opacity: "0.3",
          zIndex: "0",
          position: "absolute",
        }}
      ></div>
      <div className="contact-cta-parent">
        <div className="contact-cta-text">
          Give your bussiness the Reliable Edge
        </div>
        <Link to={"/contactUs"} className="contact-cta-button">
          Let's Connect
          <FaCircleArrowRight className="contact-cta-button-icon" />
        </Link>
      </div>
    </div>
  );
}
