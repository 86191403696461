import { FaCircleArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import heroImage from "../../../../assets/png/hero_image.png";
import "./hero.css";
import FloatingService from "./widgets/floating_services/floating_service";
import ServicePills from "./widgets/service_pills/service_pills";
import TrustedByBussiness from "./widgets/trusted_by_bussiness/trusted_by_bussiness";

export default function Hero({ data }) {
  return (
    <div className="hero">
      <div className="hero-root">
        <div className="hero-parent">
          <div className="hero-content-container">
            <TrustedByBussiness />
            <div className="header-title">
              Multiply your <br /> Success
            </div>
            <div className="header-description">
              From chaos to control, growth redefined: Your one-stop{" "}
              <span style={{ fontWeight: "600" }}>ERP Solution </span>
              for smarter operations, happier customers.
            </div>
            <ServicePills />
          </div>
          <div className="hero-image-container">
            <img className="hero-money-image" src={heroImage} alt="Hero" />
            <Link to={"/aboutUs"} className="hero-know-more">
              Know More
              <FaCircleArrowRight className="cta-icon" />
            </Link>
          </div>
          <FloatingService data={data} />
        </div>
      </div>
    </div>
  );
}
