import { ScrollRestoration } from "react-router-dom";
import planning from "../../assets/png/planning.jpg";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import ContactCTA from "../contact_us/widgets/contact_cta/contact_cta";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./about_us.css";
import ProcessExpansion from "./widgets/process/process";

export default function AboutUs() {
  return (
    <div>
      <div style={{ backgroundColor: "#EBF7FE" }}>
        <Navbar />
        <div className="about-us-title-gradient">
          <div>
            About <span className="about-us-span">Reliable</span>
          </div>
          <VerticalSpacer height={"40px"} />
        </div>
      </div>
      <ScrollRestoration />
      <div className="about-us-desccription">
        Reliable Suite was established in 2009 by a team of core software
        professionals endeavoring to provide the best IT solutions to the
        industry. The company headquartered in Kuwait, in a very short period,
        has emerged to become one of the prominent players in Kuwait. The credit
        for the same goes to the organization's commitment to serve the industry
        in the best possible way by delivering value-added services to the
        clients. In tune with the organization's philosophy are its highly
        motivated employees who believe in giving their best in whatever project
        we undertake.
      </div>
      <VerticalSpacer height={"8px"} />
      <div className="about-divider" />
      <div className="our-process-title-container">Our Process</div>
      <VerticalSpacer height={"56px"} />
      <div className="process-parent">
        <div className="process-image-container">
          <img src={planning} alt={"process"} className="process-image" />
        </div>
        <div className="process-text-container">
          <ProcessExpansion />
        </div>
      </div>
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}
