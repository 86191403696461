import { MdOutlineStar } from "react-icons/md";
import "./trusted_by_bussiness.css";

export default function TrustedByBussiness() {
  return (
    <div className="trusted-tag-container">
      <MdOutlineStar className="trusted-tag-star" />
      Trusted By Bussiness
    </div>
  );
}
