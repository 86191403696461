import quotes from "../../../../assets/png/quote.png";
import VerticalSpacer from "../../../../components/utils/vertical_spacer";
import "./testimonials.css";

export default function Testimonials() {
  const testimonials = [
    {
      testimonial:
        "Since implementing Reliable Suite, we've seen a 20% reduction in operational costs and a 30% increase in overall efficiency. The automated workflows have freed up our staff to focus on more strategic initiatives",
      person: " Taher Patrawala",
      company: "Company Name",
    },
    {
      testimonial:
        "With their innovative solutions and expertise, Reliable Entrepreneur is shaping the future of business. We are proud to partner with them and leverage their capabilities to achieve our full potential.",
      person: " Taher Patrawala",
      company: "Company Name",
    },
  ];

  return (
    <div className="testimonial-root">
      <div className="testimonial-content-container">
        <div className="testimonial-content-title">What Our Customer Say</div>
        <div className="testimonial-content-description">
          Our systems are diligently developed, carefully crafted and rigorously
          reiterated to fulfil your needs. We understand your needs better than
          anybody.
        </div>
      </div>
      <div className="testimonial-parent">
        <TestimonialsCard testimonial={testimonials[0]} />
        <TestimonialsCard testimonial={testimonials[1]} />
      </div>
    </div>
  );
}

function TestimonialsCard({ testimonial }) {
  return (
    <div className="testimonial-card">
      <img src={quotes} alt="quotes" className="testimonial-quote" />
      <VerticalSpacer height={"16px"} />
      <div className="testimonial-card-text">{testimonial.testimonial}</div>
      <VerticalSpacer height={"32px"} />
      <div className="testimonial-card-client-name">{testimonial.person}</div>
      <div className="testimonial-card-company-name">{testimonial.company}</div>
    </div>
  );
}
