import { FaCheckCircle } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { ScrollRestoration } from "react-router-dom";
import erpServiceLogo from "../../assets/png/service_logo.png";
import sales from "../../assets/sales.mp4";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import ContactCTA from "../contact_us/widgets/contact_cta/contact_cta";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./erp_service_screen.css";

export default function ErpServiceScreen() {
  const erpData = [
    {
      title: "Financial Accounting",
      description:
        " Manage multi-currency transactions, track detailed accounts, generate accurate reports, and streamline post-dated checks - all in one powerful ERP solution.",
      points: [
        "Multi-Level Chart of Accounts",
        "Multi Financial Years",
        "Multi-Currency",
        "General Ledger",
        "Accounts Receivable",
        "Accounts Payable",
        "PDC Management",
        "Bank Reconciliation",
        "Inter Company Multi Currency Bank Transfers",
        "Budgeting",
        "Financial Reporting",
        "Drill down from ledger to vouchers",
      ],
      url: "https://images.pexels.com/photos/53621/calculator-calculation-insurance-finance-53621.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Inventory Management",
      description:
        "Achieve operational excellence: Ensure timely fulfillment, minimize holding costs, and gain complete inventory visibility with our intelligent management system.",
      points: [
        "Product Management",
        "Multi-Level Categories",
        "Multi-Level UOM’s",
        "Multi Locations",
        "Reorder Levels",
        "Stock Adjustments",
        "Product Movements and Audits",
        "Multiple Barcodes",
      ],
      url: "https://images.pexels.com/photos/6169659/pexels-photo-6169659.jpeg",
    },
    {
      title: "Warehouse Management",
      description:
        "Increase productivity, reduce costs: Gain real-time data, maximize storage space, and optimize deliveries for a profitable warehouse.",
      points: ["Inter Location Transfers", "Section & Bins"],
      url: "https://images.pexels.com/photos/4481259/pexels-photo-4481259.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Procurement Management",
      description:
        "Unlock cost savings, gain control: Streamline procurement processes, optimize spend, and improve supplier relationships for sustainable growth.",
      points: [
        "Vendor Management",
        "Multi-Currency PO’s & PI’s",
        "Landed Cost Calculation",
        "Request for Quotations",
        "Purchase Order generation",
        "Purchase Invoice / Goods Receipt",
        "Supplier Payments in multiple currencies.",
        "Advance Payments and Setlements.",
        "Supplier Credit Notes",
        "Supplier Debit Notes",
        "Financial Integration",
      ],
      url: "https://images.pexels.com/photos/5025635/pexels-photo-5025635.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Sales Management",
      description:
        "Streamline sales, skyrocket revenue: Gain real-time insights, optimize processes, and drive efficiency for a winning sales force.",
      points: [
        "Customers Management, Branches, Contacts",
        "Sales Enquiries",
        "Customer Quotation",
        "Sales Orders",
        "Delivery Note",
        "Sales Invoice",
        "Sales Returns",
        "Debit Notes",
        "Credit Notes",
        "Sales Receipts",
        "Customers sales price history",
        "Financial Integration",
        "Approval Workflow in each sales process stages",
      ],
      url: "https://images.pexels.com/photos/3727469/pexels-photo-3727469.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Production Management",
      description:
        "Boost productivity, achieve on-time delivery: Gain real-time visibility, optimize workflows, and minimize risks for smoother, faster production.",
      points: [
        "Production Order management",
        "Convert Larger packs into smaller packs",
        "Production Costing",
      ],
      url: "https://images.pexels.com/photos/1267338/pexels-photo-1267338.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Job Order Management",
      description:
        "Seamless project execution: Simplify job orders, track progress, and deliver on time with our intuitive system.",
      points: [
        "Create Job Order",
        "Advance payments",
        "Convert Job Orders to DO or Invoice",
      ],
      url: "https://images.pexels.com/photos/6169862/pexels-photo-6169862.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Enquiries Reports",
      description:
        "Dive deep into inventory, purchases, sales, and BI dashboards for informed decisions, optimized operations, and business growth.",
      points: [
        "Inventory Reports",
        "Purchase Reports",
        "Sales Reports",
        "Financial Reports",
        "Business Intelligence (Dashboards)",
      ],
      url: "https://images.pexels.com/photos/8512441/pexels-photo-8512441.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Business Intelligence",
      description:
        "See beyond the surface, predict the future: Leverage powerful BI to uncover hidden insights, optimize operations, and stay ahead of the curve.",
      points: [
        "Sales Overview",
        "Sales Performance",
        "Sales Analysis",
        "Product Analysis",
        "Procurement Analysis",
        "Years P&L",
        "Budget vs Actual P&L",
        "Ageing Reports",
      ],
      url: "https://images.pexels.com/photos/15396235/pexels-photo-15396235/free-photo-of-people-sitting-at-a-business-meeting-and-looking-at-two-men-doing-a-presentation.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
  ];
  return (
    <div>
      <ScrollRestoration />
      <Navbar />
      <div className="erp-service-screen-parent">
        <div className="erp-service-header">
          <video
            src={sales}
            className="erp-service-header-video"
            autoPlay={true}
            loop={true}
            preload="auto"
          />
          <div className="erp-service-title-container">
            <div className="erp-service-title-content-container">
              <Tilt
                className="erp-service-header-image-container"
                perspective={150}
                tiltReverse={true}
              >
                <img
                  className="erp-service-header-image"
                  src={erpServiceLogo}
                  alt="service-logo"
                />
              </Tilt>
              <div className="erp-service-header-text">
                ERP
                <span className="erp-service-header-subtitle">
                  FINANCIAL SYSTEM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "linear-gradient(180deg, #EBF7FE 2rem, #00000000)",
            width: "100%",
          }}
        >
          <div className="erp-gap"></div>
        </div>
        {erpData.map((e, index) => (
          <div key={e}>
            <ErpServiceInfo data={e} isReverse={index % 2 !== 0} />
            <VerticalSpacer height={"40px"} />
          </div>
        ))}
      </div>
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}

function ErpServiceInfo({ data, isReverse }) {
  return (
    <div className="erp-info-root">
      <div
        className="erp-info-parent"
        style={{
          "--erp-service-reverse": isReverse === true ? "row-reverse" : "row",
        }}
      >
        <div className="erp-info-content" style={{"--erp-content-padding":isReverse === true ? "0 0 0 16px " : "0 16px 0 0"}}>
          <div
            className="erp-info-title"
            style={{
              "--erp-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.title}
          </div>
          <div
            className="erp-info-description"
            style={{
              "--erp-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.description}
          </div>
          <div className="erp-info-pointers-main">
            {data.points.map((e) => (
              <h3 key={e} className="erp-info-point">
                <FaCheckCircle className="erp-info-point-icon" />
                {e}
              </h3>
            ))}
          </div>
        </div>
        <div className="erp-info-image">
          <img
            src={data.url}
            alt="infoImage"
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="erp-info-pointers">
        {data.points.map((e) => (
          <h3 key={e} className="erp-info-point">
            <FaCheckCircle className="erp-info-point-icon" />
            {e}
          </h3>
        ))}
      </div>
    </div>
  );
}
