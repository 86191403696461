import { ToastContainer } from "react-bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import AboutUs from "./screens/about_us/about_us";
import ClinicServiceScreen from "./screens/clinic_service/clinic_service_screen";
import ContactUs from "./screens/contact_us/contact_us";
import ErpServiceScreen from "./screens/erp_service/erp_service_screen";
import HomeScreen from "./screens/home/home_screen";
import HrServiceScreen from "./screens/hr_service/hr_service_screen";
import SchoolServiceScreen from "./screens/school_service/school_service_screen";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "contactUs",
      element: <ContactUs />,
    },
    {
      path: "aboutUs",
      element: <AboutUs />,
    },
    {
      path: "erp",
      element: <ErpServiceScreen />,
    },
    {
      path: "clinic",
      element: <ClinicServiceScreen />,
    },
    {
      path: "hr",
      element: <HrServiceScreen />,
    },
    {
      path: "school",
      element: <SchoolServiceScreen />,
    },
  ]);
  return (
    <div className="App">
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
