import "bootstrap/dist/css/bootstrap.min.css";
import { ScrollRestoration } from "react-router-dom";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./contact_us.css";
import ContactCTA from "./widgets/contact_cta/contact_cta";
import ContactForm from "./widgets/contact_form/contact_form";
import Details from "./widgets/details";
import MyMapComponent from "./widgets/map";

export default function ContactUs() {
  return (
    <div>
      <ScrollRestoration />
      <div style={{ backgroundColor: "#EBF7FE" }}>
        <Navbar />
        <VerticalSpacer height={"184px"} />
        <MyMapComponent isMarkerShown={true} />
        <div className="contact-header-text-parent">
          <div className="contact-header-text">
            <div>
              Connect to a<span style={{ color: "#B13633" }}> Reliable </span>
              executive now
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form_parent">
        <Details />
        <ContactForm />
      </div>
      <VerticalSpacer height={"96px"} />
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}
