import { IoMdChatbubbles } from "react-icons/io";
import { MdLock } from "react-icons/md";
import HorizontalSpacer from "../../../../components/utils/horizontal_spacer";
import "./cta_service.css";

export default function CTAService() {
  return (
    <div className="cta-service-root">
      <CTAServiceItem
        text={"Safe and secure"}
        description={"always"}
        icon={<MdLock size={"20px"} color={"#232B5D"} />}
      />
      <HorizontalSpacer width={"16%"} />
      <CTAServiceItem
        text={"Dedicated support"}
        description={"here for you anytime"}
        icon={<IoMdChatbubbles size={"20px"} color={"#232B5D"} />}
      />
    </div>
  );
}

function CTAServiceItem({ text, description, icon }) {
  return (
    <div className="cta-service-item-parent">
      {icon}
      <div className="cta-service-item-text">{text}</div>
      <div className="cta-service-item-description">{description}</div>
    </div>
  );
}
