import React, { useEffect, useRef } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleArrowRight } from "react-icons/fa6";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import "./service_card.css";

export default function ServiceCard({ data, isReverse }) {
  const elementRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      const position = element.getBoundingClientRect().top;
      const offset = 24;

      if (position < window.innerHeight - offset) {
        element.classList.add("scale-up");
      } else {
        element.classList.remove("scale-up");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="service-root" ref={elementRef}>
      <div
        className="service-parent"
        style={{
          "--flex-direction": isReverse === true ? "row-reverse" : "row",
        }}
      >
        <div className="service-content-parent">
          <div
            style={{
              "--container-color": data.serviceContainerColor,
              "--border-radius":
                isReverse === true ? "0 32px 32px 0" : "32px 0 0 32px",
            }}
            className="service-background-content-container"
          ></div>
          <Tilt
            perspective={150}
            tiltReverse={true}
            className="service-image-logo-container"
            style={{
              "--align-self": isReverse === true ? "flex-end" : "flex-start",
            }}
          >
            <img
              className="service-image-logo"
              src={data.serviceLogo}
              alt="service-logo"
            />
          </Tilt>
          <div className="service-content-group">
            <div
              className="service-content-title"
              style={{ "--title-color": data.serviceNameColor }}
            >
              {data.serviceName}
            </div>
            <div
              className="service-content-subtitle"
              style={{ "--subtitle-color": data.serviceNameSubsetColor }}
            >
              {data.serviceNameSubset}
            </div>
            <div
              className="service-content-description"
              style={{ "--description-color": data.serviceDescriptionColor }}
            >
              {data.serviceDescription}
            </div>
            <div className="content-pointer-grid">
              {data.servicePoints.map((point) => {
                return <Pointer text={point} color={data.servicePointsColor} />;
              })}
            </div>
          </div>
          <Link
            to={data.link}
            className="service-know-more"
            style={{
              "--cta-color": data.ctaButtonColor,
              "--align-self": isReverse === true ? "flex-end" : "flex-start",
              "--cta-border-radius":
                isReverse === true ? "24px 0 24px 0" : "0 24px 0 24px",
            }}
          >
            Know More
            <FaCircleArrowRight className="cta-icon" />
          </Link>
        </div>
        <div className="service-image-parent">
          <div
            className="service-background-image-color"
            style={{
              "--image-bg-container-color": data.serviceContainerColor,
              "--border-radius":
                isReverse === true ? "32px 0 0 32px" : "0 32px 32px 0",
            }}
          >
            <div
              className="service-background-image-container"
              style={{ "--image-bg-color": data.serviceImageColor }}
            ></div>
          </div>

          <img
            className="service-image"
            src={data.serviceSectionImage}
            alt="section-clap"
          />
        </div>
      </div>
    </div>
  );
}

function Pointer({ text, color }) {
  return (
    <div
      className="service-pointer-text"
      style={{ "--service-point-color": color }}
    >
      <FaCheckCircle className="service-pointer-icon" />
      {text}
    </div>
  );
}
