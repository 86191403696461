import React, { useEffect, useRef } from "react";
import "./steps.css";

export default function Steps() {
  const stepsData = [
    {
      title: "Define your problem",
      description:
        "Identify key processes to automate, determine business size and complexity, and establish your priorities.",
    },
    {
      title: "Choose & combine the right platform",
      description:
        "Research and compare the different ERP platforms based on your needs and functionalities.",
    },
    {
      title: "Deploy & Launch",
      description:
        "Go live with confidence, knowing Reliable Suite supports your evolving needs",
    },
  ];
  return (
    <div className="steps-root">
      <div className="steps-intro-content">
        <div className="steps-title">
          A simple yet powerful & efficient process
        </div>
        <div className="steps-description">
          How our Personalised ERP Approach Works
        </div>
      </div>
      <div className="steps-wrapper">
        <StepsCard
          number={1}
          title={stepsData[0].title}
          description={stepsData[0].description}
        />
        <StepsCard
          number={2}
          title={stepsData[1].title}
          description={stepsData[1].description}
        />
        <StepsCard
          number={3}
          title={stepsData[2].title}
          description={stepsData[2].description}
        />
      </div>
    </div>
  );
}

function StepsCard({ number, title, description }) {
  const elementRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      const position = element.getBoundingClientRect().top;
      const offset = 24;

      if (position < window.innerHeight - offset) {
        element.classList.add("scale-up");
      } else {
        element.classList.remove("scale-up");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="steps-card" ref={elementRef}>
      <div className="steps-card-number">{number}</div>
      <div className="steps-card-title">{title}</div>
      <div className="steps-card-description">{description}</div>
    </div>
  );
}

// function StepsCard({ className, bgColor, stepNumber, title, description }) {
// const elementRef = useRef();

// useEffect(() => {
//   const handleScroll = () => {
//     const element = elementRef.current;
//     const position = element.getBoundingClientRect().top;
//     const offset = 100;

//     if (position < window.innerHeight - offset) {
//       element.classList.add("scale-up");
//     } else {
//       element.classList.remove("scale-up");
//     }
//   };

//   window.addEventListener("scroll", handleScroll);

//   return () => {
//     window.removeEventListener("scroll", handleScroll);
//   };
// }, []);
//   return (
//     <div
//       ref={elementRef}
//       className={className}
//       style={{
//         backgroundColor: bgColor,
//       }}
//     >
//       <div
//         style={{
//           borderRadius: "100%",
//           backgroundColor: "#fff",
//           height: "64px",
//           width: "64px",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           fontSize: "28px",
//           fontWeight: "800",
//         }}
//       >
//         {stepNumber}
//       </div>
//       <VerticalSpacer height={"24px"} />
//       <div
//         style={{
//           fontColor: "#000",
//           fontSize: "24px",
//           fontWeight: "800",
//           padding: "0 32px",
//           textAlign: "center",
//         }}
//       >
//         {title}
//       </div>
//       <VerticalSpacer height={"8px"} />
//       <div
//         style={{
//           color: "#676767",
//           fontSize: "16px",
//           padding: "0 32px",
//           textAlign: "center",
//         }}
//       >
//         {description}
//       </div>
//     </div>
//   );
// }
