import "./or_divider.css";

export default function ORDivider() {
  return (
    <div className="or-root">
      <div className="or-parent">
        <hr class="solid" className="solid-line" />
        <span className="or-title"> OR </span>
        <hr class="solid" className="solid-line" />
      </div>
    </div>
  );
}
