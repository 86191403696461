import { FaCircleArrowRight } from "react-icons/fa6";
import { IoMdChatbubbles } from "react-icons/io";
import { MdLock } from "react-icons/md";
import { Link } from "react-router-dom";
import countour from "../../../../assets/png/countour.png";
import humanCta from "../../../../assets/png/human_cta.png";

import "./cta.css";

export default function CTA() {
  return (
    <div className="cta-root">
      <div className="cta-human">
        <img src={humanCta} style={{ height: "100%" }} alt="human" />
      </div>
      <div className="cta-service-strip">
        <div className="cta-service-container">
          <div className="cta-service-item">
            <MdLock className="cta-service-item-icon" />
            <div className="cta-service-item-text">
              Safe & Secure <span style={{ fontWeight: "600" }}>Always</span>
            </div>
          </div>
          <div className="cta-service-item">
            <IoMdChatbubbles className="cta-service-item-icon" />
            <div className="cta-service-item-text">
              Dedicated support{" "}
              <span style={{ fontWeight: "600" }}>here for you anytime</span>
            </div>
          </div>
        </div>
      </div>
      <div className="cta-content">
        <div
          className="cta-content-background"
          style={{
            backgroundImage: `url(${countour})`,
            backgroundRepeat: "no-repeat",
            opacity: "0.5",
          }}
        />
        <div className="cta-content-container">
          <div className="cta-content-text">
            Give your a business the Reliable edge
          </div>
          <Link to={"/contactUs"} className="cta-know-more">
            Inquire Now
            <FaCircleArrowRight className="cta-icon" />
          </Link>
        </div>
      </div>
      <div className="cta-service-content"></div>
    </div>
  );
}
