import VerticalSpacer from "../../../components/utils/vertical_spacer";

export default function Details() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flex: "1",
        padding: "0 4%",
      }}
    >
      <FieldWidget
        title={"Address"}
        description={"Shayma Tower, Murgab, Block 3, Plot 8 A & B, 12th Floor, Office No 2, Kuwait City, Kuwait"}
      />
      <VerticalSpacer height={"48px"} />
      <FieldWidget title={"Contact No."} description={"00965 99374176"} />
      <VerticalSpacer height={"48px"} />
      <FieldWidget title={"Email"} description={"info@reliablesol.com"} />
    </div>
  );
}

function FieldWidget({ title, description }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div style={{ fontSize: "20px", color: "#234E9F", fontWeight: "600" }}>
        {title}
      </div>
      <div style={{ fontSize: "18px", fontWeight: "400" }}>{description}</div>
    </div>
  );
}
