import mobileCta from "../../../../assets/png/mobile_cta.png";
import "./mobile_cta.css";

export default function MobileCTA() {
  return (
    <div className="mobile-cta-root">
      <div className="mobile-cta-background"></div>
      <div className="mobile-cta-image">
        <img src={mobileCta} alt="mobileCtaImage" />
      </div>
      <div className="mobile-cta-content">
        <CTATag />
        <div className="mobile-description">
          Download on your iPhone or Android device and stay connected with your{" "}
          <span style={{ color: "#909090" }}>team, anywhere, anytime.</span>
        </div>
        <div className="mobile-coming-soon">Coming Soon!</div>
      </div>
    </div>
  );
}

function CTATag() {
  return <div className="cta-tag">Get started on the go</div>;
}
