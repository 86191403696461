import { FaCheckCircle } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { ScrollRestoration } from "react-router-dom";
import schoolServiceLogo from "../../assets/png/school_service_logo.png";
import school from "../../assets/school.mp4";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import ContactCTA from "../contact_us/widgets/contact_cta/contact_cta";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./school_service_screen.css";
export default function SchoolServiceScreen() {
  const schoolData = [
    {
      title: "Manage Students",
      description:
        "Transform your school into a thriving hub of learning and collaboration. Empower educators, engage students, and connect families",
      points: [
        "Student Management",
        "Faculty Managment",
        "Campus Administration",
        "Accounting & Finance",
      ],
      url: "https://images.pexels.com/photos/7972506/pexels-photo-7972506.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
  ];
  return (
    <div>
      <ScrollRestoration />
      <Navbar />
      <div className="school-service-screen-parent">
        <div className="school-service-header">
          <video
            src={school}
            className="school-service-header-video"
            autoPlay={true}
            loop={true}
            preload="auto"
          />
          <div className="school-service-title-container">
            <div className="school-service-title-content-container">
              <Tilt
                className="school-service-header-image-container"
                perspective={150}
                tiltReverse={true}
              >
                <img
                  className="school-service-header-image"
                  src={schoolServiceLogo}
                  alt="service-logo"
                />
              </Tilt>
              <div className="school-service-header-text">
                School
                <span className="school-service-header-subtitle">
                  MANAGEMENT SYSTEM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "linear-gradient(180deg, #EBF7FE 2rem, #00000000)",
            width: "100%",
          }}
        >
          <div className="school-gap"></div>
        </div>
        {schoolData.map((e, index) => (
          <div key={e}>
            <SchoolServiceInfo data={e} isReverse={index % 2 !== 0} />
            <VerticalSpacer height={"40px"} />
          </div>
        ))}
      </div>
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}

function SchoolServiceInfo({ data, isReverse }) {
  return (
    <div className="school-info-root">
      <div
        className="school-info-parent"
        style={{
          "--school-service-reverse":
            isReverse === true ? "row-reverse" : "row",
        }}
      >
        <div
          className="school-info-content"
          style={{
            "--school-content-padding":
              isReverse === true ? "0 0 0 16px " : "0 16px 0 0",
          }}
        >
          <div
            className="school-info-title"
            style={{
              "--school-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.title}
          </div>
          <div
            className="school-info-description"
            style={{
              "--school-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.description}
          </div>
          <div className="school-info-pointers-main">
            {data.points.map((e) => (
              <h3 key={e} className="school-info-point">
                <FaCheckCircle className="school-info-point-icon" />
                {e}
              </h3>
            ))}
          </div>
        </div>
        <div className="school-info-image">
          <img
            src={data.url}
            alt="infoImage"
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="school-info-pointers">
        {data.points.map((e) => (
          <h3 key={e} className="school-info-point">
            <FaCheckCircle className="school-info-point-icon" />
            {e}
          </h3>
        ))}
      </div>
    </div>
  );
}
