// import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import "./our_process.css";

export default function ProcessExpansion() {
  const data = [
    {
      key: 0,
      title: "Preliminary analysis",
      description:
        "Discover the organization’s objectives and the nature and scope of the problem under study. Propose alternative solutions: After digging into the organization’s objectives and specific problems, several solutions may have been discovered. However, alternate proposals may still come from interviewing employees, clients, suppliers, and/or consultants. Insight may also be gained by researching what competitors are doing.",
    },
    {
      key: 1,
      title: "Cost benefit analysis",
      description:
        "Analyze and describe the costs and benefits of implementing the proposed changes. In the end, the ultimate decision on whether to leave the system as is, improve it, or develop a new system will be guided by this and the rest of the preliminary analysis data.",
    },
    {
      key: 2,
      title: "Development",
      description: "The real code is written here.",
    },
    {
      key: 3,
      title: "Integration and testing",
      description:
        "All the pieces are brought together into a special testing environment, then checked for errors, bugs, and interoperability.",
    },
    {
      key: 4,
      title: "Acceptance, installation, deployment",
      description:
        "This is the final stage of initial development, where the software is put into production and runs actual business.",
    },
    {
      key: 5,
      title: "Maintenance",
      description:
        "During the maintenance stage of the SDLC, the system is assessed/evaluated to ensure it does not become obsolete. This is also where changes are made to initial software.",
    },
  ];

  return (
    <div>
      <Accordion defaultActiveKey={0} style={{ border: "none" }}>
        {data.map((e) => (
          <Card
            className="accordian-item"
            key={e.key}
            style={{
              border: "none",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
            }}
          >
            <Card.Header
              style={{ border: "none", backgroundColor: "#00000000" }}
            >
              <CustomToggle data={e}></CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={e.key} style={{ border: "none" }}>
              <Card.Body
                className="acc-body"
                style={{ paddingRight: "96px", paddingBottom: "24px" }}
              >
                {e.description}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

function CustomToggle({ children, data }) {
  const decoratedOnClick = useAccordionButton(data.key, () => {});

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  return (
    <div className="acc-title" onClick={decoratedOnClick}>
      <div className="acc-toggle-title">{data.title}</div>
      <div className="acc-toggle-number">{pad(data.key + 1)}</div>
    </div>
  );
}
