import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import relaibleRedLogo from "../../../../assets/png/reliable_red_logo.png";
import HorizontalSpacer from "../../../../components/utils/horizontal_spacer";

import "./navbar.css";
import Sidebar from "./Sidebar/sidebar";

export default function Navbar() {
  const [scrolling, setScrolling] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 40;
      setScrolling(scrollPosition > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onClickShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  return showSideBar ? (
    <Sidebar onClose={() => onClickShowSideBar()} show={showSideBar} />
  ) : (
    <div className="navbar-root">
      <div className="navbar-parent">
        <Link to={"/"} className={`logo-text ${scrolling ? "scroll" : ""}`}>
          {"RELIABLE "}
          <span style={{ fontWeight: "300", color: "#32373a" }}>
            ENTREPRENEUR
          </span>
        </Link>
        <div className="nav-link-parent">
          <NavLink text={"Home"} to={"/"} />
          <HorizontalSpacer width={"24px"} />
          <NavLink text={"About Us"} to={"/aboutUs"} />
          <HorizontalSpacer width={"24px"} />
          <ProductLink text="Products" to={"/#products"} />
          <HorizontalSpacer width={"24px"} />
          <NavLinkButton to={"/contactUs"} />
        </div>
        <FaBars className="side-menu" onClick={() => onClickShowSideBar()} />
      </div>
        <Link to={ "/"} className={`navbar-logo ${scrolling ? "scroll" : ""}`}>
        <img
          src={relaibleRedLogo}
          style={{ width: "100%" }}
          alt="reliableNavLogo"
        />
      </Link>
    </div>
  );
}

function NavLink({ text, to }) {
  return (
    <Link
      to={to}
      style={{ color: "#676767", cursor: "pointer", textDecoration: "none" }}
    >
      {text}
    </Link>
  );
}

function ProductLink({ text, to }) {
  return (
    <a
      href={to}
      style={{ color: "#676767", cursor: "pointer", textDecoration: "none" }}
    >
      {text}
    </a>
  );
}

function NavLinkButton() {
  return (
    <Link
      to={"/contactUs"}
      style={{
        backgroundImage: "linear-gradient(to right,#3684B7, #4C9F80)",
        padding: "12px 24px",
        color: "#FFFFFF",
        borderRadius: "8px",
        textDecoration: "none",
      }}
    >
      Get in Touch
    </Link>
  );
}
