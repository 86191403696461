import { Link } from "react-router-dom";
import reliableGroup from "../../../../assets/png/reliable_group.png";
import "./reliable_cta.css";

export default function ReliableCTA() {
  return (
    <div className="reliable-cta-root">
      <div className="reliable-cta-parent">
        <div className="reliable-cta-countour" />
        <div className="reliable-cta-content">
          <div className="reliable-cta-title">
            {
              "Customise & combine the different options to fit your needs into "
            }
            <span className="reliable-cta-suite-title">Reliable Suite</span>
          </div>
          <Link to={"/aboutUs"} className="relaible-cta-button">
            Learn more
          </Link>
        </div>
        <div className="reliable-suite-container">
          <img
            className="animated-element"
            src={reliableGroup}
            alt="reliableGroupLogo"
          />
        </div>
      </div>
    </div>
  );
}
