import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

export default function MyMapComponent() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCF7U4fA8a0uRfr3bhiEjggjmUrjjQ1EG4",
  });

  const renderMap = () => {
    return (
      <div style={{ padding: "0 4%" }}>
        <GoogleMap
          center={{ lat: 29.3729903, lng: 47.9866498 }}
          zoom={16}
          mapContainerStyle={{
            height: "560px",
            width: "100%",
            borderRadius: "24px",
            boxShadow: "0px 4px 16px #00000024",
          }}
        >
          <Marker position={{ lat: 29.3729903, lng: 47.9866498 }} key={':'} >
          </Marker>
        </GoogleMap>
      </div>
    );
  };

  return isLoaded ? renderMap() : null;
}
