import { FaCheckCircle } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { ScrollRestoration } from "react-router-dom";
import hr from "../../assets/hr.mp4";
import hrServiceLogo from "../../assets/png/hr_service_logo.png";
import VerticalSpacer from "../../components/utils/vertical_spacer";
import ContactCTA from "../contact_us/widgets/contact_cta/contact_cta";
import CTAService from "../home/widgets/CTA_Service/cta_service";
import Footer from "../home/widgets/Footer/footer";
import Navbar from "../home/widgets/Navbar/navbar";
import "./hr_service_screen.css";
export default function HrServiceScreen() {
  const hrData = [
    {
      title: "Employee Registration & Hire Process",
      description:
        "Streamline hiring: Simplify onboarding from application to integration with our intuitive employee management system.",
      points: ["Employee Registration", "Employee Contract & Hiring"],
      url: "https://images.pexels.com/photos/5668859/pexels-photo-5668859.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Employee Maintenance",
      description:
        "Empower your workforce: Simplify administration, optimize performance, and build a thriving employee ecosystem.",
      points: [
        "Employee Personal Records",
        "Employee Dependent Records",
        "Annual Leaves",
        "Medical Leaves",
        "Resuming from Leaves",
        "Service Termination Process",
        "Employee Salary & Position history",
      ],
      url: "https://images.pexels.com/photos/164686/pexels-photo-164686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title: "Other Features",
      description:
        "Automate payroll, generate insightful reports, receive timely document alerts, and manage flexible compensation easily. Simplify compliance, optimize finances, and ensure employee satisfaction",
      points: [
        "Expiry Alerts for Employee Documents",
        "Employee Self Service Module",
        "Monthly Payroll Process",
        "Enter monthly Additions/Deduction and Overtimes",
        "Review Payroll for each employee",
        "Bank Reports Generation",
        "Bank Disc generation",
        "Various Payroll Reports",
      ],
      url: "https://plus.unsplash.com/premium_photo-1679923034919-be797fcd161d?q=80&w=3394&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];
  return (
    <div>
      <ScrollRestoration />
      <Navbar />
      <div className="hr-service-screen-parent">
        <div className="hr-service-header">
          <video
            src={hr}
            className="hr-service-header-video"
            autoPlay={true}
            loop={true}
            preload="auto"
          />
          <div className="hr-service-title-container">
            <div className="hr-service-title-content-container">
              <Tilt
                className="hr-service-header-image-container"
                perspective={150}
                tiltReverse={true}
              >
                <img
                  className="hr-service-header-image"
                  src={hrServiceLogo}
                  alt="service-logo"
                />
              </Tilt>
              <div className="hr-service-header-text">
                Human Resources
                <span className="hr-service-header-subtitle">
                  MANAGEMENT SYSTEM
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "linear-gradient(180deg, #EBF7FE 2rem, #00000000)",
            width: "100%",
          }}
        >
          <div className="hr-gap"></div>
        </div>
        {hrData.map((e, index) => (
          <div key={e}>
            <HrServiceInfo data={e} isReverse={index % 2 !== 0} />
            <VerticalSpacer height={"40px"} />
          </div>
        ))}
      </div>
      <CTAService />
      <ContactCTA />
      <Footer />
    </div>
  );
}

function HrServiceInfo({ data, isReverse }) {
  return (
    <div className="hr-info-root">
      <div
        className="hr-info-parent"
        style={{
          "--hr-service-reverse": isReverse === true ? "row-reverse" : "row",
        }}
      >
        <div
          className="hr-info-content"
          style={{
            "--hr-content-padding":
              isReverse === true ? "0 0 0 16px " : "0 16px 0 0",
          }}
        >
          <div
            className="hr-info-title"
            style={{
              "--hr-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.title}
          </div>
          <div
            className="hr-info-description"
            style={{
              "--hr-service-padding":
                isReverse === true ? "0 0 0 24px " : "0 24px 0 0",
            }}
          >
            {data.description}
          </div>
          <div className="hr-info-pointers-main">
            {data.points.map((e) => (
              <h3 key={e} className="hr-info-point">
                <FaCheckCircle className="hr-info-point-icon" />
                {e}
              </h3>
            ))}
          </div>
        </div>
        <div className="hr-info-image">
          <img
            src={data.url}
            alt="infoImage"
            height={"100%"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="hr-info-pointers">
        {data.points.map((e) => (
          <h3 key={e} className="hr-info-point">
            <FaCheckCircle className="hr-info-point-icon" />
            {e}
          </h3>
        ))}
      </div>
    </div>
  );
}
