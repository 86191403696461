import HorizontalSpacer from "../../../../../../components/utils/horizontal_spacer";
import "./floating_service.css";

export default function FloatingService({ data }) {
  return (
    <div className="floating-service-parent">
      <div className="floating-service-text-section">
        <div className="floating-service-title">A Product for every need</div>
        <div className="floating-service-description">
          Our diverse range of products across
        </div>
      </div>
      <HorizontalSpacer width={"8%"} />
      <div className="floating-services-image-parent">
        {data.map((e) => (
          <ServiceLogo key={e} serviceLogo={e.serviceLogo} />
        ))}
      </div>
    </div>
  );
}

function ServiceLogo({ serviceLogo }) {
  return (
    <div className="floating-services-image-container">
      <img
        src={serviceLogo}
        className="floating-services-image"
        alt="serviceLogo"
      />
    </div>
  );
}
