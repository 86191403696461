import React, { useEffect, useRef } from "react";
import "./key_figures.css";

export default function KeyFigures() {
  const figures = [
    {
      value: "25 Yrs",
      description: "of industry expertise",
    },
    {
      value: "100+",
      description: "happy clients",
    },
    {
      value: "50+",
      description: "tailored solutions",
    },
    {
      value: "4+",
      description: "country presence",
    },
  ];
  return (
    <div className="keyfigures-root">
      <div className="keyfigures-parent">
        <div className="keyfigures-title">Key Figures</div>
        <div className="keyfigures-grid ">
          {figures.map((e) => (
            <KeyFigureCard
              key={e}
              value={e.value}
              description={e.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function KeyFigureCard({ value, description }) {
  const elementRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      const position = element.getBoundingClientRect().top;
      const offset = 100;

      if (position < window.innerHeight - offset) {
        element.classList.add("scale-up");
      } else {
        element.classList.remove("scale-up");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="figure-card" ref={elementRef}>
      <div className="figure-value">{value}</div>
      <div className="figure-description">{description}</div>
    </div>
  );
}
