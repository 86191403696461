import emailjs from "@emailjs/browser";
import React, { useRef } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./contact_form.css";

export default function ContactForm() {
  const formRef = useRef();

  const onSubmit = (e) => {
    console.log(formRef.current.email.value);
    e.preventDefault();
    if (
      formRef.current.email.value === "" ||
      formRef.current.subject.value === "" ||
      formRef.current.message.value === ""
    ) {
      console.log("Error");
      toast.error("Please Fill All The Fields", {
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "colored",
      });
    } else {
      emailjs
        .sendForm(
          "service_ymcjrrh",
          "template_8onc58o",
          formRef.current,
          "pJyKOLovwkKAdKVw5",
        )
        .then(
          (result) => {
            console.log(result.text);
            toast.success("Message Sent", {
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              theme: "colored",
            });
          },
          (error) => {
            console.log(error.text);
            toast.error("Message Sending Failed", {
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              theme: "colored",
            });
          },
        );
    }
    formRef.current.reset();
  };
  return (
    <Form ref={formRef} className="contact-form-root" onSubmit={onSubmit}>
      <Form.Group key={1} className="mb-3" controlId="formBasicEmail">
        <FloatingLabel
          controlId="floatingInput"
          label="Email address"
          className="mb-3"
        >
          <Form.Control
            name="email"
            borderColor="#FF0000"
            type="email"
            placeholder="EMAIL"
          />
        </FloatingLabel>
      </Form.Group>
      <Form.Group key={2} className="mb-3" controlId="formBasicSubject">
        <FloatingLabel
          controlId="floatingInputSubject"
          label="Subject"
          className="mb-3"
        >
          <Form.Control name="subject" type="text" placeholder="SUBJECT" />
        </FloatingLabel>
      </Form.Group>
      <Form.Group key={3} className="mb-3" controlId="formBasicMessage">
        <FloatingLabel
          controlId="floatingInputMessage"
          label="Message"
          className="mb-3"
        >
          <Form.Control
            as="textarea"
            name="message"
            style={{ height: "200px" }}
            placeholder="MESSAGE"
          />
        </FloatingLabel>
      </Form.Group>
      <div className="contact-form-button" onClick={onSubmit}>
        SUBMIT
      </div>
    </Form>
  );
}
