import "./service_pills.css";
export default function ServicePills() {
  return (
    <div className="service-pills">
      <Pills serviceLabel={"ERP"} bgColor={"#56BDCE"} />
      <Pills serviceLabel={"Clinic"} bgColor={"#4EA397"} />
      <Pills serviceLabel={"School"} bgColor={"#306AA6"} />
      <Pills serviceLabel={"HR"} bgColor={"#449061"} />
    </div>
  );
}

function Pills({ serviceLabel, bgColor }) {
  return (
    <div
      className="pills"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {serviceLabel}
    </div>
  );
}
