import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import reliableLogo from "../../../../../assets/png/relaible_logo.png";
import reliableLongLogo from "../../../../../assets/png/reliable_red_logo.png";
import "./sidebar.css";

export default function Sidebar({ onClose, show }) {
  const onClick = () => {
    onClose();
  };
  return (
    <aside className="sidebar-root">
      <div className="sidebar-header">
        <div className="sidebar-header-image">
          <img style={{ width: "20%" }} src={reliableLongLogo} alt={"logo"} />
          {/* <div className="sidebar-header-text">
            {"RELIABLE "}
            <span style={{ fontWeight: "300", color: "#32373a" }}>
              ENTREPRENEUR
            </span>
          </div> */}
        </div>
        <IoClose className="sidebar-header-close" onClick={onClose} />
      </div>
      <div className="sidebar-navlinks-container">
        <div className="links-image-container">
          <img src={reliableLogo} className="link-image" alt={"reliableLogo"} />
        </div>
        <div className="link-container">
          <Link className="sidebar-navlink" to="/" onClick={() => onClick()}>
            Home
          </Link>
          <a className="sidebar-navlink" href="/#products" onClick={() => onClick()}>
            Products
          </a>
          <Link
            className="sidebar-navlink"
            to="/aboutUs"
            onClick={() => onClick()}
          >
            About Us
          </Link>
          <Link
            className="sidebar-get-touch"
            to="/contactUs"
            onClick={() => onClick()}
          >
            Get In Touch
          </Link>
        </div>
      </div>
      <div className="reliable-text">
        RELIABLE
        <div style={{ fontWeight: "200", padding: "0 6px" }}>ENTREPRENEUR</div>
      </div>
    </aside>
  );
}
