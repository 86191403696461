import VerticalSpacer from "./../../components/utils/vertical_spacer";
import CTA from "./widgets/CTA/cta";
import Footer from "./widgets/Footer/footer";
import Hero from "./widgets/Hero/hero";
import KeyFigures from "./widgets/Key_Figures/key_figures";
import MobileCTA from "./widgets/Mobile_CTA/mobile_cta";
import Navbar from "./widgets/Navbar/navbar";
import ORDivider from "./widgets/OR_Divider/or_divider";
import ReliableCTA from "./widgets/Reliable_CTA/reliable_cta";
import ServiceCard from "./widgets/Service_Card/service_card";
import Steps from "./widgets/Steps/steps";
import Testimonials from "./widgets/Testimonials/testimonials";

//Service Data
import { ScrollRestoration } from "react-router-dom";
import clinicSectionImage from "../../assets/png/clinic_section.png";
import clinicServiceLogo from "../../assets/png/clinic_service_logo.png";
import erpSectionImage from "../../assets/png/erp_section.png";
import hrSectionImage from "../../assets/png/hr_section.png";
import hrServiceLogo from "../../assets/png/hr_service_logo.png";
import schoolSectionImage from "../../assets/png/school_section.png";
import schoolServiceLogo from "../../assets/png/school_service_logo.png";
import serviceLogo from "../../assets/png/service_logo.png";
import "./home_screen.css";

export default function HomeScreen() {
  const data = [
    {
      serviceLogo: serviceLogo,
      serviceName: "ERP",
      serviceNameColor: "#2E5E67",
      serviceNameSubset: "FINANCIAL SYSTEM",
      serviceNameSubsetColor: "#2E5E67aa",
      serviceDescription:
        "Empower your business. Streamline operations, gain real-time insights, boost collaboration, and unlock growth.",
      serviceDescriptionColor: "#2E5E67aa",
      serviceSectionImage: erpSectionImage,
      servicePoints: [
        "Financial Accounting",
        "Purchase Management",
        "Inventory Management",
        "Sales Management",
        "Warehouse Management",
        "Enquiries and Reports",
      ],
      servicePointsColor: "#648990",
      serviceContainerColor: "#EEF8FA",
      serviceImageColor: "#7DCAD7",
      ctaButtonColor: "#648990",
      link: "/erp",
    },
    {
      serviceLogo: schoolServiceLogo,
      serviceName: "School",
      serviceNameColor: "#1E413D",
      serviceNameSubset: "MANAGEMENT SYSTEM",
      serviceNameSubsetColor: "#1E413Daa",
      serviceDescription:
        "Transform your school into a thriving hub of learning and collaboration.\nEmpower educators, engage students, and connect families",
      serviceDescriptionColor: "#1E413Daa",
      serviceSectionImage: schoolSectionImage,
      servicePoints: [
        "Student Management",
        "Faculty Managment",
        "Campus Administration",
        "Accounting & Finance",
      ],
      servicePointsColor: "#1E413DAA",
      serviceContainerColor: "#EDF5F4",
      serviceImageColor: "#5DACA3",
      ctaButtonColor: "#587370",
      link: "/school",
    },
    {
      serviceLogo: clinicServiceLogo,
      serviceName: "Clinic",
      serviceNameColor: "#11284E",
      serviceNameSubset: "MANAGEMENT SYSTEM",
      serviceNameSubsetColor: "#11284Eaa",
      serviceDescription:
        "Transform your clinic into a well-oiled machine. Automate appointments, streamline workflows, and provide exceptional patient care.",
      serviceDescriptionColor: "#11284Eaa",
      serviceSectionImage: clinicSectionImage,
      servicePoints: [
        `Patient's Record`,
        `Doctor's interface`,
        "Insurance Management",
        "Enquiries / Reports",
        "Invoicing",
      ],
      servicePointsColor: "#11284EAA",
      serviceContainerColor: "#E9EDF5",
      serviceImageColor: "#4F73AF",
      ctaButtonColor: "#4E5F7D",
      link: "/clinic",
    },
    {
      serviceLogo: hrServiceLogo,
      serviceName: "Human Resource",
      serviceNameColor: "#224928",
      serviceNameSubset: "MANAGEMENT SYSTEM",
      serviceNameSubsetColor: "#224928aa",
      serviceDescription:
        "Revolutionize your HR processes. Automate tasks, streamline workflows, and empower your employees.",
      serviceDescriptionColor: "#224928aa",
      serviceSectionImage: hrSectionImage,
      servicePoints: [
        "Employee Maintenance",
        "Employee Leaves",
        "Employee Termination",
        "Employee History",
        "Employee Registration & Hire Process",
        "Other Features",
      ],
      servicePointsColor: "#224928AA",
      serviceContainerColor: "#ECF4ED",
      serviceImageColor: "#69A773",
      ctaButtonColor: "#5B795F",
      link: "/hr",
    },
  ];

  return (
    <div>
      <ScrollRestoration />
      <div style={{ backgroundColor: "#EBF7FE" }}>
        <Navbar />
        <VerticalSpacer height={"144px"} />
        <Hero data={data} />
      </div>
      <div className="home-gradient-background" id="products">
        <div className="home-service-title">
          <div>
            Our
            <span style={{ color: "#B13633" }}> Reliable </span>
            Range of Products
          </div>
        </div>
      </div>
      {data.map((service, index) => {
        return (
          <div>
            <ServiceCard isReverse={index % 2 !== 0} data={service} />
            {index !== data.length - 1 ? (
              <VerticalSpacer height={"96px"} />
            ) : null}
          </div>
        );
      })}
      <VerticalSpacer height={"72px"} />
      <ORDivider />
      <VerticalSpacer height={"72px"} />
      <ReliableCTA />
      <VerticalSpacer height={"72px"} />
      <Steps />
      <VerticalSpacer height={"96px"} />
      <MobileCTA />
      <VerticalSpacer height={"96px"} />
      <Testimonials />
      <VerticalSpacer height={"88px"} />
      <KeyFigures />
      <VerticalSpacer height={"88px"} />
      <CTA />
      <Footer />
    </div>
  );
}
